.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(284deg, #fedd4e, #fcb43a);
  color: #fff;
  margin-bottom: 1rem;
}

.footer  {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background-image: linear-gradient(284deg, #feda37, #d48806);
  color: #fff;
  bottom: 0px;
  left: 0px;
  right: 0px;
    padding: 0.5rem 1rem;
  margin-bottom: 0px;
}

body {
  margin-bottom:100px;
}

.visible {
  display:block
}

.invisible {
  display: none;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer-title {
  font-size: 0.9rem;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer-subtitle {
  font-size: 0.9rem;
  font-weight: bold;
}

.header-title::after {
  content: ' ▸ ';
  padding: 0 0.5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: block;
}

.search-panel__filters {
  flex: 1;
}


.search-panel__results {
  flex: 3;

}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#searchbox {
  margin-bottom: 2rem;
}

#pagination {
  margin: 2rem auto;
  text-align: center;
}

.hit-name {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 10px;
}
.audio-box {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 10px;
  max-width:95%;
}

.ais-Hits-item {
  width: calc(100% - 1rem)
}
.hit-authors {
  margin-top: 3px;
  font-size: 0.8rem;
}

@media (max-width: 767px) {
#refinement-list {
  margin-right: 20px;
  max-width: 300px;
  display: none;
  transition: display 1s, opacity 0.5s linear;
  margin-bottom: 40px;
}
}
.hit-publication-year {
  font-size: 0.8rem;
  margin-top: 20px;
}

.hit-rating {
  margin-top: 3px;
  font-size: 0.8rem;
}


.ais-Hits-item {
  padding: 30px;
  box-shadow: none;
  border: 1px solid lighten(lightgray, 8%);
}


@media (min-width: 768px) {
  #refinement-list {
    margin-right: 20px;
    max-width: 300px;
    display:'block';
  }

  .search-panel {
    display: flex;
  }

}

#filter-titles {
 display:block;
margin-bottom: 10px;
font-size: smaller;
color: gray;
cursor: pointer;
}
